import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		activeType: [{
				value: "8",
				label: "买赠类营销活动"
			},
			{
				value: "9",
				label: "抽奖类营销活动"
			},
			{
				value: "10",
				label: "秒杀类营销活动"
			},
			{
				value: "13",
				label: "特权商品营销活动"
			},
			{
				value: "14",
				label: "团购类营销活动"
			},
			{
				value: "15",
				label: "一区一策营销活动"
			},
			{
				value: "16",
				label: "异业合作营销活动"
			},
			{
				value: "17",
				label: "基础类营销活动"
			}

		],
		currentActive:""
	},
	mutations: {
		saveCurrentActive(state, payload){
		  state.currentActive = payload.currentActive
		},
	},
	actions: {},
	modules: {}
})
