import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MobileHome from '../views/mobile/yd-Home.vue'
import MobileClass from '../views/mobile/yd-Class.vue'
import MobileTeacher from '../views/mobile/yd-Teacher.vue'
import MobileAboutUs from '../views/mobile/yd-AboutUs.vue'
import AboutUs from '../views/AboutUs.vue'
import Class from '../views/Class.vue'
import Teacher from '../views/Teacher.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/class',
    name: 'Class',
    component: Class
  },
  {
    path: '/teacher',
    name: 'Teacher',
    component: Teacher
  },
  {
    path: '/mobile/home',
    name: 'MobileHome',
    component: MobileHome
  },
  {
    path: '/mobile/class',
    name: 'MobileClass',
    component: MobileClass
  },
  {
    path: '/mobile/teacher',
    name: 'MobileTeacher',
    component: MobileTeacher
  },
  {
    path: '/mobile/aboutus',
    name: 'MobileAboutUs',
    component: MobileAboutUs
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
