<template>
  <div class="home">
		<div :class="scrollTop == 0?'nav':'nav nav-active'">
			<div class="inner">
				<div class="logo-box" v-if="false">
					<img src="../../assets/teacher/logo.png" alt="" class="logo">
					<span></span>
				</div>				
				<div class="list-box">
          <router-link to="/mobile/home" class="item nav-item1">首页</router-link>
          <router-link to="/mobile/class" class="item nav-item1">课程中心</router-link>
          <router-link to="/mobile/teacher" class="item active">名师介绍</router-link>
					<router-link to="/mobile/aboutus" class="item nav-item1">关于我们</router-link>
				</div>
			</div>
		</div>
		<div class="header-banner">
			<el-carousel arrow="never">
				<el-carousel-item>
					<img src="../../assets/teacher/1-1.jpg" alt="" style="width:100%">
				</el-carousel-item>
				<el-carousel-item>
					<img src="../../assets/teacher/1-2.jpg" alt="" style="width:100%">
				</el-carousel-item>
				<el-carousel-item>
					<img src="../../assets/teacher/1-3.jpg" alt="" style="width:100%">
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">01</span>
						<span class="text">金牌教研员</span>
					</div>
					<div class="title-english">Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/6.jpg" alt="" class="teacher-img"></img>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">02</span>
						<span class="text">数学金牌讲师</span>
					</div>
					<div class="title-english">Math Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/1.jpg" alt="" class="teacher-img">
				<img src="../../assets/teacher/7.jpg" alt="" class="teacher-img">
				<img src="../../assets/teacher/15.jpg" alt="" class="teacher-img">
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">03</span>
						<span class="text">语文金牌讲师</span>
					</div>
					<div class="title-english">Chinese Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/11.jpg" alt="" class="teacher-img">
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">04</span>
						<span class="text">物理金牌讲师</span>
					</div>
					<div class="title-english">Physics Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/9.jpg" alt="" class="teacher-img">
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">05</span>
						<span class="text">化学金牌讲师</span>
					</div>
					<div class="title-english">Chemistry Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/12.jpg" alt="" class="teacher-img">
				<img src="../../assets/teacher/3.jpg" alt="" class="teacher-img">
				<img src="../../assets/teacher/2.jpg" alt="" class="teacher-img">
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">06</span>
						<span class="text">政治金牌讲师</span>
					</div>
					<div class="title-english">Politics Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/8.jpg" alt="" class="teacher-img"></img>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">07</span>
						<span class="text">历史金牌讲师</span>
					</div>
					<div class="title-english">History Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/4.jpg" alt="" class="teacher-img"></img>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">08</span>
						<span class="text">生物金牌讲师</span>
					</div>
					<div class="title-english">Biology Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/13.jpg" alt="" class="teacher-img"></img>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">09</span>
						<span class="text">地理金牌讲师</span>
					</div>
					<div class="title-english">Geography Gold Teacher</div>
				</div>
				<img src="../../assets/teacher/14.jpg" alt="" class="teacher-img"></img>
			</div>
		</div>
		<div class="container">
			<div class="component-footer">
				<div class="component-footer-common">
					<div>用户隐私政策</div>
					<div class="line"></div>
					<div>用户服务协议</div>
					<div class="line"></div>
					<div>吉ICP备2022005057号-2</div>
					<div class="line"></div>
					<div>服务热线:400-8767985</div>
				</div>
				<!-- <div>Copyright @ 2009-2022 菲尔莱（北京）科技有限公司 All Rights Reserved 京ICP备18056030号-24</div>
				<div>公司地址：北京市朝阳区来广营容和路1号 叶青大厦北园6层</div> -->
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'Teacher',
  components: {
    
  },
  data(){
	  return {
			scrollTop:0,
	  }
  },
  mounted() {
	const _this = this;
		window.onscroll = function(){
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			// console.log("scrollTop=========",scrollTop)
			_this.scrollTop = scrollTop
		}
	}
}
</script>

<style lang="less" scoped="scoped">
.nav{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 99;
	height: 210px;
	transition: all .3s;
	padding: 0 30px;
	font-size: 28px;
	box-sizing: border-box;
	.inner{
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.logo-box{
			display: flex;
			align-items: center;
		}
		.logo{
			display: block;
			width: 40px;
			height: 40px;
			cursor: pointer;
			margin-right: 10px;
		}
		.list-box{
			display: flex;
			.item{
				font-size: 48px;
				line-height: 96px;
				margin-left: 37px;
				cursor: pointer;
				padding: 0 69px;
			}
			.active{
				font-weight: 700;
				border-radius: 48px;
				color: #ff1001;
				background-color: #ffefeb;
			}
			.nav-item1{
				color: #3b3a43;
			}
			.nav-item2{
				color: #ffffff;
			}
		}
	}
}
.nav-active{
	background: #fff;
	box-shadow: 0 3px 3px #eee;
}
/deep/ .el-carousel__container{
	height: 780px;
}
/deep/ .header-banner .el-carousel__indicators--horizontal{
	bottom: 78px !important;
	left: 100px !important;
	transform: none !important;
}
/deep/ .header-banner .el-carousel__button{
	width: 48px !important;
	height: 6px !important;
	border-radius: 4px !important;
	background: rgba(59,58,67,.2) !important;
	opacity: 1 !important;
}
/deep/ .is-active .el-carousel__button{
	background: #ffffff !important;
}
.container{
	position: relative;
	z-index: 2;
	.count-box{
		position: relative;
		width: 1180px;
		margin: 0 auto;
		padding-top: 140px;
		background-color: #fff;
	}
	.count-box2{
		padding-top: 80px;
		background-color: #fff;
	}
	.teacher-img{
		width: 100%;
		margin: 200px auto;
		display: block;
	}
}
.box-title{
	width: 100%;
  text-align: center;
  line-height: 1;
  font-weight: 600;
	.title-zh{
		font-size: 102px;
		color: #3b3a43;
		.num{
			position: relative;
			font-size: 210px;
			top: 4px;
			margin-right: 20px;
		}
		.text{
			position: relative;
			top: -4px;
		}
	}
	.title-english{
		color: #f0f0f0;
		font-size: 96px;
		margin-top: 6px;
	}
}
.component-footer{
	width: 100%;
	height: 240px;
	background: #4f5669;
	font-size: 40px;
	color: #fffdfd;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	line-height: 30px;
	display: flex;
	align-items: center;
	.component-footer-common{
		display: flex;
		align-items: center;
		// justify-content: center;
		.line{
			width: 1px;
			height: 70px;
			margin: 0 48px;
			background: #fffdfd;
		}
	}
}

.class-box{
	position: relative;
	z-index: 3;
	width: 1110px;
	height: 366px;
	box-sizing: border-box;
	padding: 65px 0 70px 75px;
	margin: 40px auto;
	.class-text{
		padding: 55px 69px 55px 180px;
		box-sizing: border-box;
		font-family: PingFangSC-Regular;
		font-size: 17px;
		color: #3b3a43;
		letter-spacing: 0;
		text-align: justify;
		line-height: 39.48px;
		box-shadow: 0 4px 30px 0 #f3f3f3;
		border-radius: 18px;
	}
	.class-img{
		width: 212px;
		height: 212px;
		display: block;
		border-radius: 18px;
		position: absolute;
		left: 0px;
		top: 0;
	}
}
.out-box{
	width: 1110px;
	margin: 0 auto;		
	.title{
		font-size: 36px;
		color: #3b3a43;
		letter-spacing: 0;
		text-align: justify;
		line-height: 1;
		text-align: left;
		// text-indent: 47px;
		font-weight: 600;
	}
	.info-box{
		width: 1110px;
		margin: 40px auto;
		height: 240px;
		flex: 1 1;
		border-radius: 9px;
		background: #fff6f4;
		border-radius: 20px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		position: relative;
		.info-star{
			width: 191px;
			height: 145px;
			position: absolute;
			left: 20px;
			bottom: 0;
		}
		.info-class{
			width: 840px;
			display: flex;
			flex-wrap: wrap;
			.class-item{
				display: flex;
				align-items: center;
				width: 33%;
				margin-bottom: 20px;
				img{
					width: 46px;
					height: 46px;
					display: flex;
				}
				.class-text{
					font-size: 20px;
					color: #3b3a43;
					letter-spacing: 1px;
					margin: 0 20px;
				}
			}
		}
		.info-class2{
			.class-text{
				font-size: 16px !important;
			}
		}
	}
	.course-list{
		margin-top: 40px;
		display: flex;
		flex-wrap: wrap;
		.list-item:nth-child(2n){
			// margin-left: 94px;
		}
		.list-item{
			cursor: pointer;
			transition: all .2s ease-in-out;
			position: relative;
			width: 474px;
			height: 280px;
			background: #fff;
			box-shadow: 0 2px 40px 0 rgba(133,138,150,.12);
			border-radius: 0 24px 24px 0;
			box-sizing: border-box;
			margin-bottom: 75px;
			margin-left: 54px;
			.item-head{
				height: 100px;
				display: flex;
				align-items: center;
				background: #f7f8fa;
				position: relative;
				overflow: hidden;
				.item-number{
					opacity: .21;
					font-family: Impact;
					font-size: 77px;
					color: #cfcfcf;
					letter-spacing: 0;
					line-height: 77px;
					font-weight: 800;
					position: absolute;
					right: 33px;
					bottom: -10px;
				}
				.head-info{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-content: center;
					.info-title{
						font-family: PingFangSC-Semibold;
						font-size: 23px;
						color: #ff4935;
						letter-spacing: 0;
						text-align: justify;
						line-height: 23px;
						font-weight: 800;
						padding-left: 60px;
						position: relative;
					}
					.info-title::before{
						content: "";
						width: 10px;
						height: 10px;
						display: block;
						border-radius: 50%;
						position: absolute;
						top: 8px;
						left: 35px;
						background: #ff4935;
					}
				}
			}
			.item-content{
				font-size: 15px;
				color: #3b3a43;
				letter-spacing: 0;
				text-align: justify;
				line-height: 30px;
				margin-top: 32px;
				padding: 0 44px;
			}
			.item-left{
				position: absolute;
				bottom: 0;
				left: -8px;
				width: 8px;
				height: 100%;
				background: -webkit-linear-gradient(top,#ffa77d,#ff2a1d);
				border-radius: 24px 0 0 24px;
			}
		}
	}
}
</style>