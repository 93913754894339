<template>
  <div style="width:100%">
    <div :class="scrollTop == 0?'nav':'nav nav-active'">
			<div class="inner">
				<div class="logo-box" v-if="false">
					<img src="../assets/teacher/logo.png" alt="" class="logo">
					<span></span>
				</div>
				<div class="list-box">
          <router-link to="/" class="item nav-item1">首页</router-link>
					<router-link to="/class" class="item nav-item1">课程中心</router-link>
          <router-link to="/teacher" class="item nav-item1">名师介绍</router-link>
					<router-link to="/aboutus" class="item active">关于我们</router-link>
				</div>
			</div>
		</div>
    <img src="../assets/about.jpg" alt="" style="width:100%">
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  components: {
    
  },
  data(){
	  return {
			scrollTop:0,
	  }
  },
	beforeCreate(){
		const _this = this
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      _this.$router.push("/mobile/aboutus")
    }
	},
  mounted() {
	const _this = this;
		window.onscroll = function(){
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			_this.scrollTop = scrollTop
		}
	}
}
</script>

<style lang="less" scoped>
.nav{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 99;
	height: 70px;
	transition: all .3s;
	.inner{
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
    .logo-box{
			display: flex;
			align-items: center;
		}
		.logo{
			display: block;
			width: 40px;
			height: 40px;
			cursor: pointer;
      margin-right: 10px;
		}
		.list-box{
			display: flex;
			.item{
				font-size: 16px;
				line-height: 32px;
				margin-left: 37px;
				cursor: pointer;
				padding: 0 23px;
			}
			.active{
				font-weight: 700;
				border-radius: 16px;
				color: #ff1001;
				background-color: #ffefeb;
			}
			.nav-item1{
				color: #3b3a43;
			}
			.nav-item2{
				color: #ffffff;
			}
		}
	}
}
.nav-active{
	background: #fff;
	box-shadow: 0 3px 3px #eee;
}

</style>